@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+Black&display=swap');

.header {
  background-color: #111;
  color: white;
  text-align: center;
  font-family: 'Alegreya Sans Black', sans-serif;
  position: relative;
  overflow: visible; /* Ensure it does not clip its content */
  height:500px;
}


.slogan {
  background-color: red;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 0;
  position: relative;
  margin-top: -2%;
  height: 28px;
}

.slogan-content {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.background {
  position: relative;
  background-color: #111;
  height: 500px;
}

.background-image {
  width: 100%;
  height: auto;
}

.stars1, .stars2 {
  position: absolute;
  width: 45%; /* Scale down the image */
  background-color: #111111; /* Match header background color */
  height:450px
}

.stars1 {
  top: 0px;
  left: 0px;
  height:100%;
}

.stars2 {
  top: 0px;
  right: 0px;
  height:100%;
}

.logo {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;  /* Adjust this value to the desired width */
    height: auto;  /* Adjust this value to the desired height */
    object-fit: cover;
  }

.rocket-frame {
  position: absolute;
  left: 10%;
  bottom: -0.8%;
}

.rocket-frame-image {
  width: 65%;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 60%;
  left: 15%;
  transform: translate(10%, -50%);
  z-index: 5;
}

.btn {
    display: inline-block;
    padding: 10px 20px; /* Increase padding for longer buttons */
    margin: 10px 0;
    text-decoration: none;
    color: white;
    border-radius: 25px; /* Large radius for both ends */
    font-size: 1.3em; /* Increase font size */
    font-weight: bold; /* Make the font bold */
    position: relative; /* Required for positioning the pseudo-element */
    width: 150%;
    height: 20%;
}

.btn-community {
background-color: white; /* White background */
color: red; /* Red font */
border: 2px solid red; /* Red border */
z-index:5;

}

.btn-launch {
background-color: yellow; /* Yellow background */
color: black; /* Black font */
border: 2px solid black; /* Black border */
z-index:5;
}
  

.rocketman {
  position: absolute;
  right: -5%;
  bottom: -3.5%;
  z-index: 2; /* Ensure rocketman is above the cloud */
}

.rocketman-image {
  width: 50%;
}

.cloud-image {
  position: absolute;
  right: 0;
  bottom: -1%;
  width: 45%;
  height: auto;
  z-index: 1;
}

h1 {
    position: absolute;
    top: 35%; /* Adjust the position to be higher */
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%; /* Increase width to allow wrapping */
    font-size: 2.5em; /* Increase font size */
    text-align: left;
    line-height: 1.2; /* Adjust line height for better spacing */
    white-space: normal; /* Allow wrapping */
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
    .header {
        height: 500px;
      }
      
    .slogan {
        margin-top: -6%;
      }

    .background {
        position: relative;
        text-align: center; /* Center align the contents */
        height: 100%;
    }
    
    .background-image {
        width: 100%;
        height: auto;
    }
    .logo {
      top: 20px;
    }
    h1 {
        top: 140px;
        font-size: 1em;
        width: 90%;
        text-align: center;
    }
    
  
    .buttons {
      top: 170px;
      width: 200px; 
      transform: translate(10%, 0%);
      margin-bottom: 0px;
      z-index: 5;
    }
  
    .btn {
      padding: 1px 2px;
      font-size: 1em;
      width: 100%;
      z-index:5
    }

    .rocket-frame {
        position: absolute;
        bottom:0px;
        left:-25px;
    }
      
    .rocket-frame-image {
        width: 25%;
    }
  
    .rocketman {
        position: absolute;
        right: 0px;
        bottom:0px;

    }
      
    .rocketman-image {
    width: 50%;
    }
    
    .cloud-image {
    position: absolute;
    bottom:-3.5%;
    width:100%;
    }
    .stars1 {
      height:50%;
    }
    
    .stars2 {
      height:50%;
    }

  }




