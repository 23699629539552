@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+Black&display=swap');

.trending-coins {
    background-color: white;
    padding: 50px 20px; /* Add padding to the section */
    text-align: center;
    font-family: 'Alegreya Sans Black', sans-serif;
  }
  
  .trending-coins h2 {
    margin-bottom: 30px;
    font-size: 2em;
    font-weight: bold; /* Make the title bold */
  }
  
  .trending-coins .highlight {
    color: red;
  }
  
  .content {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto auto;
    column-gap: 20px; /* Increase column gap for better spacing */
    row-gap: 20px; /* Keep row gap for proper vertical spacing */
    justify-items: center;
    align-items: start;
    padding: 0 20px; /* Add padding to the content */
  }
  
  .text-item {
    max-width: 300px;
    text-align: left;
    padding: 10px; /* Add padding to the text items */
    line-height: 1.5; /* Increase line height for better readability */
  }
  
  .text-item .red {
    font-weight: bold; /* Make the subtitle bold */
    color: red;
  }

  
  
  .image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px; /* Add padding around the image */
  }
  
  .trending-image {
    width: 100%;
    max-width: 230px;
    height: auto;
  }
  
/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .content {
      flex-direction: column;
      align-items: center;
    }
  
    .image-content {
      order: -1; /* Move the image-content to the top */
    }
  
    .trending-image {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
  
    .text-item {
      text-align: center;
    }
  }
  .trending-coins h2 {
    font-size: 1.5em;
  }
  
  
