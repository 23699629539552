@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+Black&display=swap');

.roadmap {
    background-color: white;
    padding: 50px 0;
    text-align: center;
    font-family: 'Alegreya Sans Black', sans-serif;
  }
  
  .roadmap h2 {
    margin-bottom: 30px;
    font-size: 2em;
  }
  
  .roadmap .highlight {
    color: red;
  }
  
  .content {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto auto;
    column-gap: 10px; /* Reduced column gap for closer columns */
    row-gap: 20px; /* Keep row gap for proper vertical spacing */
    justify-items: center;
    align-items: center;
  }
  
  .roadmap-item {
    background-color: white;
    border: 2px solid red;
    border-radius: 10px;
    padding: 20px;
    width: 250px;
    height: 200px; /* Fixed height for equal sizes */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Individual shadow directions */
  .roadmap-item:nth-child(1) {
    box-shadow: 5px 5px 10px rgba(255, 0, 0, 0.4); /* Shadow to the right bottom */
  }
  
  .roadmap-item:nth-child(2) {
    box-shadow: -5px 5px 10px rgba(255, 0, 0, 0.4); /* Shadow to the left bottom */
  }
  
  .roadmap-item:nth-child(4) {
    box-shadow: 5px -5px 10px rgba(255, 0, 0, 0.4); /* Shadow to the right top */
  }
  
  .roadmap-item:nth-child(5) {
    box-shadow: -5px -5px 10px rgba(255, 0, 0, 0.4); /* Shadow to the left top */
  }
  
  .roadmap-item .title {
    font-size: 1.5em;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .roadmap-item p {
    margin: 5px 0; /* Reduced margin for smaller gaps between lines */
    line-height: 1.2; /* Adjusted line height for smaller gaps */
  }
  
  .rocket {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
  
  .rocket-image {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
  
/* Responsive styles for mobile */
@media (max-width: 768px) {
    .content {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, auto); /* Adjust to accommodate all items */
    }
  
    .rocket {
      grid-column: 1 / 2;
      grid-row: 1 / 2; /* Move the rocket to the top */
      margin-bottom: 20px;
    }
  
    .rocket-image {
      width: 200px; /* Adjust as needed */
      height: auto;
    }
  
    .roadmap-item {
      max-width: 100%;
    }
  
    .roadmap h2 {
      font-size: 1.5em;
    }
  }
  
  