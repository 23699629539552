@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+Black&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6, p, div {
  font-family: 'Alegreya Sans Black', sans-serif;
  margin: 0;
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6, p, div {
  font-family: 'Alegreya Sans Black', sans-serif;
}
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
}

header {
  background-color: #ff4d4d;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.logo {
  height: 50px;
}

.buttons {
  margin-top: 20px;
}

.join-button, .launch-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
}


.trending-coins {
  background-color: white;
  padding: 50px 0;
  text-align: center;
}

.trending-coins h2 {
  margin-bottom: 30px;
}

.coins {
  display: flex;
  justify-content: center;
}

.coin {
  width: 30%;
  padding: 0 20px;
}

.top-chains {
  background-color: #f9f9f9;
  padding: 50px 0;
  text-align: center;
}

.chains img {
  width: 10%;
  margin: 0 10px;
}

.roadmap {
  background-color: white;
  padding: 50px 0;
  text-align: center;
}

.roadmap h2 {
  margin-bottom: 30px;
}

.roadmap-steps {
  display: flex;
  justify-content: center;
}

.step {
  width: 20%;
  padding: 0 20px;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.social-buttons {
  margin-top: 20px;
}

.twitter-button, .telegram-button, .litepaper-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
}


css
Copy code
.App {
  background-color: #f2f2f2;
}

.content-wrapper {
  padding: 20px; /* Adjust as needed for spacing */
  max-width: 1200px; /* Optional: to center content and limit width */
  margin: 0 auto; /* Center content horizontally */
}

/* Reset default browser margin and padding */
body, h1, h2, h3, p, div {
  margin: 0;
  padding: 0;
}

/* Typography and default styles */
body {
  font-family: 'Alegreya Sans Black', sans-serif;
}

/* Responsive font sizes */
@media (max-width: 768px) {
  h1 {
    font-size: 1.5em;
  }
  
  h2 {
    font-size: 1.25em;
  }
  
  h3 {
    font-size: 1em;
  }
  
  p {
    font-size: 0.875em;
  }
}

@media (max-width: 768px) {
  .content-wrapper {
      padding: 0px; /* Adjust padding for mobile */
  }
  
  .coin, .step {
      width: 100%; /* Full width on mobile */
      padding: 0px; /* Adjust padding for mobile */
  }
}
