@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+Black&display=swap');

.top-chains {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  font-family: 'Alegreya Sans Black', sans-serif;
}

.top-chains h2 {
  margin-bottom: 30px;
  font-size: 2em;
  font-weight: bold;
}

.top-chains .highlight {
  color: red;
}

.chains {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 800px; /* Control the maximum width to enable wrapping */
  margin: 0 auto;
}

.chain-image {
  object-fit: scale-down; /* Ensure the image fits within the box without distortion */
}

/* Specific styles for each image with their original aspect ratio */
.chain-image-1 {
  width: 180px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
}

.chain-image-2 {
  width: 140px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
}

.chain-image-3 {
  width: 160px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
}

.chain-image-4 {
  width: 150px; /* Adjust as needed */
  height: 35px; /* Adjust as needed */
}

.chain-image-5 {
  width: 145px; /* Adjust as needed */
  height: 38px; /* Adjust as needed */
}

.chain-image-6 {
  width: 155px; /* Adjust as needed */
  height: 33px; /* Adjust as needed */
}

.chain-image-7 {
  width: 150px; /* Adjust as needed */
  height: 35px; /* Adjust as needed */
}

.chain-image-8 {
  width: 140px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
}

.chain-image-9 {
  width: 150px; /* Adjust as needed */
  height: 35px; /* Adjust as needed */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .top-chains h2 {
    font-size: 1.75em; /* Slightly smaller font size for header */
  }

  .chains {
    max-width: 100%; /* Ensure it uses full width on mobile */
    gap: 10px; /* Reduce gap for mobile */
  }

  .chain-image {
    width: 45%; /* Ensure each row has only two images */
    height: auto; /* Maintain aspect ratio */
    object-fit: scale-down; /* Ensure the image fits within the box without distortion */
  }
}

@media (max-width: 480px) {
  .top-chains h2 {
    font-size: 1.5em; /* Smaller font size for header */
  }

  .chains {
    max-width: 100%; /* Ensure it uses full width on mobile */
    gap: 10px; /* Reduce gap for mobile */
  }

  .chain-image {
    width: 45%; /* Ensure each row has only two images */
    height: auto; /* Maintain aspect ratio */
    object-fit: scale-down; /* Ensure the image fits within the box without distortion */
  }
}
