@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+Black&display=swap');
.footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    font-family: 'Alegreya Sans Black', sans-serif;
    height: 500px;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative; /* Ensure it does not clip its content */
    height: 400px;
    bottom: 0px;
  }
  
  .footer-image {
    margin: 0px;
  }
  
  .left-image {
    position: absolute;
    left: 0;
    bottom: -30.5%; /* Adjust as needed */
    height: auto;
    width: 380px;
  }
  
  .right-image {
    position: absolute;
    right: 0;
    bottom: -30.5%; /* Adjust as needed */
    height: auto;
    width: 380px;
  }
  
  .background-image {
    position: absolute;
    left: 50%;
    top: 50%; /* Adjust as needed */
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    z-index: -1; /* Ensure it is behind the other content */
  }
  
  .middle-image {
    z-index: 1;
    max-width: 400px; /* Adjust as needed */
  }
  
  /* Buttons inside footer */
  .footer-buttons {
    display: flex;
    justify-content: center;
    bottom:10px;
  }
  
  .footer-buttons .button {
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    transition: background 0.3s;
    margin-right: 30px;
    font-size: 24px;
  }
  
  .footer-buttons .twitter {
    background-color: #1da1f2;
    width:150px;
    height: auto;
  }
  
  .footer-buttons .telegram {
    background-color: #0088cc;
    width:150px;
    height: auto;
  }
  
  .footer-buttons .litepaper {
    background-color: #ff4500;
    width:150px;
    height: auto;
  }

  .mobile-footer-background {
    position: relative;
    bottom: -4.5%;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  
  .mobile-footer-background img {
    width: 100%;
    height: 100%;
    object-fit: scale-down; /* Ensure the image covers the whole background */
  }

  .mobile-footer-buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .mobile-footer-buttons .button {
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    transition: background 0.3s;
    width: 150px;
  }
  
  .mobile-footer-buttons .twitter {
    background-color: #1da1f2;
  }
  
  .mobile-footer-buttons .twitter:hover {
    background-color: #0d8bcd;
  }
  
  .mobile-footer-buttons .telegram {
    background-color: #0088cc;
  }
  
  .mobile-footer-buttons .telegram:hover {
    background-color: #006699;
  }
  
  .mobile-footer-buttons .litepaper {
    background-color: #ff4500;
  }
  
  .mobile-footer-buttons .litepaper:hover {
    background-color: #cc3700;
  }
  
  @media (max-width: 768px) {
    .footer-content {
        display: none; /* Hide footer content on mobile */
      }
    
      .mobile-footer-background {
        display: block; /* Show mobile footer background on mobile */
      }

    .footer{
        height:700px;
    }
  
    .footer-buttons .button {
      display: none;
    }
  
    .footer-buttons {
      display: none;
    }
  
  
    .footer-image {
      display: none; /* Hide images on mobile */
    }
  
    .mobile-footer-background {
      display: block; /* Show mobile footer background on mobile */
    }
  }
  
